import Noty from 'noty';
export const useNoty = () => {
  const $noty = {
    show: (text) => {
      new Noty({ text, type: 'info' }).show();
    },
    error: (text) => {
      new Noty({ text, type: 'error' }).show();
    },
    success: (text) => {
      new Noty({ text, type: 'success' }).show();
    },

  };
  return { $noty };
};
