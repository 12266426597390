<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <article
    ref="vldParent"
    class="workspace-users__user-card vld-parent"
    :class="{ 'workspace-users__user-card_pending': isPending}"
  >
    <div
      :class="isPending
        ? 'flex-row flex-justify-content-start flex-gap-4 flex-grow-1 mb-0'
        : 'flex-row-between flex-align-items-start mb-2'"
    >
      <div class="user-info">
        <default-avatar
          :photo-link="user.avatar"
          size="xs"
          class="user-info__avatar"
        />

        <h1 class="user-info__name text_xs text_medium ellipsis">
          {{ user.full_name }}
        </h1>

        <p class="user-info__email text_xs ellipsis">
          {{ user.email }}
        </p>
      </div>

      <div class="header-labels">
        <default-label
          v-if="isPending"
          :label-name="$t('status.invitePending')"
          custom-type="warning"
          size="xs"
        />

        <default-label
          :label-name="isAdmin ? $tc('common.admin') : $tc('common.member')"
          :custom-type="isAdmin || isPending ? 'info' : 'warning'"
          size="xs"
        />
      </div>
    </div>

    <div class="flex-row-between">
      <div v-if="user.available_credentials?.length">
        <p class="text_xs mb-05 color_tertiary">
          {{ $tc('common.credential', 2) }}
        </p>

        <user-accounts
          :accounts="user.available_credentials"
        />
      </div>

      <div class="margin-left">
        <default-tooltip
          v-if="hasTooltip"
          :show.sync="showTooltip"
          data-closable="usersUser"
          :items="dataTooltip"
          :list-position="'right'"
          :has-triangle="false"
          @choose="tooltipHandler"
        >
          <default-button
            class="workspace-users__user-card-menu-button"
            icon-name="bx-dots-vertical-rounded"
            form="square"
            size="m"
          />
        </default-tooltip>
      </div>
    </div>

    <users-info-modal
      :show-modal.sync="modals.info"
      :user="user"
    />

    <delete-confirmation-modal
      :show.sync="modals.confirmation"
      :text="`Do you want to delete ${user.full_name} from space ${account.name}?`"
      button-text="Delete from space"
      :name="'delete' + user._id"
      @confirm="exitFromSpace"
    />
  </article>
</template>

<script>
  import DefaultTooltip from '@/components/base/uiKit/DefaultTooltip';
  import DefaultAvatar from '@/components/base/uiKit/DefaultAvatar';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import DefaultLabel from '@/components/base/uiKit/DefaultLabel.vue';
  import UserAccounts from '@/components/preferencesPage/workspace/users/user/UserAccounts.vue';
  import UsersInfoModal from '@/components/preferencesPage/workspace/users/UsersInfoModal';
  import DeleteConfirmationModal from '@/modals/DeleteConfirmationModal.vue';
  import { mapGetters } from 'vuex';
  import { exitFromSpace, changeAdmin } from '@/api/userMethods';
  import { resendEmailInvite, revokeEmailInvite } from '@/api/spaceMethods';
  import { loaderMixin } from '@/mixins/loaderMixin';

  export default {
    name: 'UsersUser',
    components: {
      DefaultTooltip,
      DefaultButton,
      DefaultLabel,
      UserAccounts,
      UsersInfoModal,
      DeleteConfirmationModal,
      DefaultAvatar,
    },
    mixins: [loaderMixin],
    props: {
      user: {
        type: Object,
        required: true,
      },
      isPending: {
        type: Boolean,
      },
    },
    data () {
      return {
        showTooltip: false,
        adminBg: {
          color: '#1F77F2',
        },
        memberBg: {
          color: '#4F4F4F',
        },
        modals: {
          confirmation: false,
          info: false,
        },
      };
    },
    computed: {
      ...mapGetters({
        account: 'account/account',
        loggedUser: 'user/user',
      }),
      admin () {
        return this.account.admins.includes(this.loggedUser._id);
      },
      isAdmin () {
        return this.account.admins.includes(this.user._id);
      },
      currentUser () {
        return this.user.email === this.loggedUser.email;
      },
      hasTooltip () {
        return this.admin || this.currentUser;
      },
      dataTooltip () {
        if (!this.hasTooltip) return;

        if (this.isPending) {
          return [
            {
              name: this.$t('workspaceSettings.settingsList.members.user.actions.resendInvite'),
              value: 'resendInvite',
              iconName: 'bx-envelope',
            },
            {
              name: this.$t('workspaceSettings.settingsList.members.user.actions.revokeInvite'),
              value: 'revokeInvite',
              iconName: 'bx-x',
              class: 'color_danger',
            },
          ];
        } else if (this.admin) {
          return [
            {
              name: this.$t('workspaceSettings.settingsList.members.user.actions.manageAccess', {
                socialName: this.$socialName,
              }),
              value: 'info',
            },
            {
              name: !this.isAdmin
                ? this.$t('workspaceSettings.settingsList.members.user.actions.revokeAdmin')
                : this.$t('workspaceSettings.settingsList.members.user.actions.grantAdmin'),
              value: 'admin',
            },
            {
              name: this.$t('actions.delete'),
              value: 'delete',
              class: 'color_danger',
            },
          ];
        } else {
          return [
            {
              name: this.$t('actions.delete'), 
              value: 'delete',
              class: 'color_danger',
            },
          ];
        }
      },
    },
    methods: {
      tooltipHandler (item) {
        switch (item.value) {
        case 'info':
          this.modals.info = true;
          break;
        case 'admin':
          this.changeAdmin();
          break;
        case 'delete':
          this.modals.confirmation = true;
          break;
        case 'resendInvite':
          this.resendInvite();
          break;
        case 'revokeInvite':
          this.revokeInvite();
          break;
        }
      },
      async changeAdmin () {
        try {
          const newSpace = await changeAdmin(this.user._id, this.account._id, !this.isAdmin);
          this.$store.commit('account/UPDATE_SPACES_LIST', newSpace);
          this.$noty.success(
            this.isAdmin
              ? this.$t('workspacePage.notifications.userRevokedAdmin')
              : this.$t('workspacePage.notifications.userGrantedAdmin')
          );
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
      async exitFromSpace () {
        try {
          const newSpace = await exitFromSpace(this.user._id, this.account._id);
          this.$store.commit('account/UPDATE_SPACES_LIST', newSpace);
          this.$noty.success(
            this.$t('workspaceSettings.notifications.userDeletedFromSpace')
          );
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
      async resendInvite () {
        try {
          this.loaded = false;
          await resendEmailInvite(this.account._id, this.user._id);
          this.$noty.success(
            this.$t('workspaceSettings.notifications.emailInviteResent')
          );
        } catch (error) {
          this.$noty.error(error.message);
        } finally {
          this.loaded = true;
        }
      },
      async revokeInvite () {
        try {
          this.loaded = false;
          await revokeEmailInvite(this.account._id, this.user._id);
          this.$emit('revoke-invite', this.user._id);
          this.$noty.success(
            this.$t('workspaceSettings.notifications.emailInviteRevoked')
          );
        } catch (error) {
          this.$noty.error(error.message);
        } finally {
          this.loaded = true;
        }
      },
    },
  };
</script>

<style lang="scss">
.workspace-users__user-card {
  padding: 8px 12px 12px;
  border-radius: 12px;
  background-color: var(--neutral-50);
  min-height: max-content;
  z-index: initial;

  .user-info {
    display: grid;
    column-gap: 4px;
    grid-auto-flow: column;
    grid-template-columns: min-content;
    grid-template-rows: repeat(2, min-content);

    &__avatar {
      grid-row: 1/3;
    }

    &__name {
      color: var(--text-color);
    }

    &__email {
      color: var(--color-text-tetriary);
    }
  }

  .header-labels {
    display: flex;
  }

  &-menu-button {
    background-color: var(--neutral-50);

    &:hover {
      background-color: var(--neutral-100);
    }
  }

  &_pending {
    display: flex;
    padding: 8px 12px;

    .user-info {
      width: 226px;
    }

    .header-labels {
      flex: 1;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: minmax(min-content, 120px);
      column-gap: 8px;
      justify-items: start;
      justify-content: start;
    }
  }
}
</style>
