<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <portal to="body-end">
    <modal
      :key="name"
      :name="name"
      :width="width"
      :height="height"
      :adaptive="true"
      @closed="close"
    >
      <div
        class="default-modal"
      >
        <slot />
        <default-button
          class="default-modal__close-button"
          color="neutral"
          form="circle"
          size="m"
          icon-name="bx-x"
          @action="close"
        />
      </div>
    </modal>
  </portal>
</template>
<script>
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  export default {
    name: 'DefaultModal',
    components: {
      DefaultButton,
    },
    props: {
      show: {
        type: Boolean,
        required: true,
      },
      name: { // для рендера нескольких модалок в одном компоненте нужны разные ключи
        type: String,
        required: true,
      },
      width: {
        type: [String, Number],
        required: true,
      },
      height: {
        type: String,
        default: 'auto',
      },
    },
    watch: {
      show (v) {
        if (v) {
          this.$modal.show(this.name);
        } else {
          this.$modal.hide(this.name);
        }
      },
    },
    methods: {
      close () {
        this.$emit('update:show', false);
      },
    },
  };
</script>
<style lang="scss">
// вся стилизация настраивается прокидываемым компонентом.
// Для дефолтных модалок (подтверждения/удаления) нужно добавить блоки, например, нижние кнопки
.default-modal {
  @include parent-size;
  position: relative;
  border-radius: 8px;
  background: var(--background-color);

  &__close-button {
    position: absolute;
    top: 0;
    left: calc(100% + 8px);
  }
}

</style>
