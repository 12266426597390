/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { computed } from 'vue';
import i18n from '@/i18n';

export const workflowSteps = computed(() => ({
  if_email_opened: i18n.t('conditions.ifEmailOpened'),
  if_email_link_clicked: i18n.t('conditions.ifEmailLinkClicked'),
  if_message_link_clicked: i18n.t('conditions.ifMessageLinkClicked'),

  if_connected: i18n.t('conditions.ifConnected'),
  if_is_open: i18n.t('conditions.ifIsOpenProfile'),
  ab_testing: i18n.t('conditions.ABTesting'),

  if_has_work_email: i18n.t('conditions.ifHasWorkEmail'),
  if_has_predicted_email: i18n.t('conditions.ifHasEnrichedEmail'),
  if_has_personal_email: i18n.t('conditions.ifHasPersonalEmail'),

  li_connect: i18n.t('actions.sendConnectionRequest'),

  li_connect_by_email: i18n.t('actions.sendConnectionByEmail'),
  li_send_message: i18n.t('actions.sendMessage'),
  li_send_inmail: i18n.t('actions.sendInMail'),
  li_actions: i18n.t('actions.performAction'),
  send_email: i18n.t('actions.sendEmail'),
  enrich_profile: i18n.t('actions.enrichProfile'),
  send_webhook: i18n.t('actions.sendWebhook'),
  push_to_crm: i18n.t('actions.pushToCrm'),
}));
