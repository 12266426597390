<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <validation-observer v-slot="{ passes }">
    <form
      ref="vldParent"
      class="vld-parent"
      @submit.prevent="passes(saveChanges)"
    >
      <basic-avatar
        class="mb-2"
        :avatar="avatarImg"
        @change="uploadAvatarHandler"
      />
      <div class="preferences-page__form-control">
        <label class="text_m">
          {{ $t('workspaceSettings.settingsList.basicInfo.workspaceName.label') }}
          <span class="preferences-page__form-required">*</span>
        </label>
        <default-input
          :value.sync="formData.name"
          name="Workspace name"
          validation-string="required"
          class="preferences-page__form-input"
          size="s"
          :placeholder="$t('workspaceSettings.settingsList.basicInfo.workspaceName.placeholder')"
        />
      </div>
      <div class="preferences-page__form-control">
        <label
          class="text_m"
          @click="formData.is_deduplicate_profiles = !formData.is_deduplicate_profiles"
        >
          {{ $t('workspaceSettings.settingsList.basicInfo.deduplicate.label') }}
        </label>
        <div class="preferences-page__form-input">
          <default-switcher
            class="left"
            :is-checked="formData.is_deduplicate_profiles"
            @change="formData.is_deduplicate_profiles = !formData.is_deduplicate_profiles"
          />
        </div>
      </div>
      <default-button
        class="margin-left"
        type="submit"
        :title="$t('actions.saveChanges')"
        color="primary"
        form="rectangle"
        size="m"
      />
    </form>
  </validation-observer>
</template>
<script>
  import DefaultInput from '@/components/base/uiKit/DefaultInput';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import BasicAvatar from '@/components/preferencesPage/user/basic/BasicAvatar';
  import { mapGetters } from 'vuex';
  import { loaderMixin } from '@/mixins/loaderMixin';
  import { updateSpace, uploadSpaceAvatar } from '@/api/spaceMethods';
  import { ValidationObserver } from 'vee-validate';
  import DefaultSwitcher from '@/components/base/uiKit/DefaultSwitcher.vue';

  export default {
    components: {
      DefaultInput,
      DefaultButton,
      ValidationObserver,
      DefaultSwitcher,
      BasicAvatar,
    },
    mixins: [loaderMixin],
    data () {
      return {
        formData: {
          name: '',
          is_deduplicate_profiles: false,
          avatarImg: null,
        },
      };
    },
    computed: {
      ...mapGetters({
        account: 'account/account',
      }),
    },
    created () {
      this.formData.name = this.account.name;
      this.formData.is_deduplicate_profiles = this.account.is_deduplicate_profiles;
    },
    beforeMount () {
      this.avatarImg = this.account.avatar ? this.account.avatar + '?no_cache=true' : null;
    },
    methods: {
      async saveChanges () {
        this.loaded = false;
        try {
          const newSpace = await updateSpace(this.$route.params.id, this.formData);
          this.$store.commit('account/UPDATE_SPACES_LIST', newSpace);
          this.$noty.success(
            this.$t('workspaceSettings.settingsList.basicInfo.notifications.nameChanged')
          );
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },
      async uploadAvatarHandler (e) {
        try {
          const file = e.target.files[0];
          this.avatarImg = URL.createObjectURL(file);
          await uploadSpaceAvatar(file);

          this.$noty.success(
            this.$t('workspaceSettings.settingsList.basicInfo.notifications.avatarChanged')
          );
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
    },
  };
</script>
