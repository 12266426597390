<template>
  <portal to="body-end">
    <transition-group
      name="snack"
      tag="ul"
      class="snackbar flex-column-centered"
    >
      <default-snack
        v-for="snack in $snackbar"
        :key="snack._id"
        :snack="snack"
        class="mt-2"
      />
    </transition-group>
  </portal>
</template>

<script setup>
  import DefaultSnack from '@/components/base/uiKit.v3/DefaultSnack.vue';
  import { useSnack } from '@/lib/useSnack';

  const { $snackbar } = useSnack();
</script>

<style lang="scss">
.snackbar {
  min-width: 288px;
  position: fixed;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  pointer-events: none;
}

.snack-enter-active {
  transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1.1);
}

.snack-leave-active {
  transition: all 0.5s cubic-bezier(0.16, 1, 0.3, 1.1);
  position: absolute;
}

.snack-move {
  transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1.1);
}

.snack-enter {
  transform: translateY(calc(100% + 16px));
}

.snack-leave-to {
  opacity: 0;
}
</style>
