/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { getAllCredentials, getCredential } from '@/api/credentialsMethods';

const state = () => ({
  credentialsList: null,
  selectedCredential: null,
  authorization: null,
});

const getters = {
  credentialsList: state => {
    return state.credentialsList;
  },
  selectedCredential: state => {
    return state.selectedCredential;
  },
  authorization: state => {
    return state.authorization;
  },
};

const mutations = {
  SET_AUTHORIZATION (state, payload) {
    const credentialsList = state.credentialsList?.slice(0) || [];
    const selectedCred = credentialsList.find(credential => credential._id === payload._id);
    selectedCred.auth_status = payload;

    state.credentialsList = credentialsList;
    state.authorization = payload;
  },
  SET_CREDENTIALS_LIST (state, payload) {
    state.credentialsList = payload;
  },
  UPDATE_CREDENTIALS_LIST (state, payload) {
    const credentialsList = state.credentialsList?.slice(0) || [];

    if (!credentialsList.length) {
      state.selectedCredential = payload;
    }
    const existingIndex = credentialsList.findIndex(credential => credential._id === payload._id);
    if (state.selectedCredential?._id === payload._id) {
      state.selectedCredential = payload;
    }
    if (existingIndex > -1) {
      credentialsList.splice(existingIndex, 1, payload);
    } else {
      if (payload._id) {
        credentialsList.push(payload);
      }
    }

    state.credentialsList = credentialsList;
  },
  DELETE_CREDENTIALS (state, deleteId) {
    const credentialsList = state.credentialsList?.slice(0) || [];
    const existingIndex = credentialsList.findIndex(credential => credential._id === deleteId);
    if (existingIndex > -1) {
      credentialsList.splice(existingIndex, 1);
    }
    state.credentialsList = credentialsList;
  },
  SET_PROXY (state, payload) {
    const credentialsList = state.credentialsList?.slice(0) || [];
    const credentials = credentialsList.find(credential => credential._id === payload.credentials);
    if (state.selectedCredential) {
      if (state.selectedCredential._id === payload._id) {
        state.selectedCredential.setting_proxy = payload.setting_proxy;
      }
      if (credentials) {
        credentials.setting_proxy = payload.setting_proxy;
      }
    }
  },
  UPDATE_UNREAD_COUNTER (state, payload) {
    const credentialsList = state.credentialsList?.slice(0) || [];
    const credentials = credentialsList.find(credential => credential._id === payload._id);
    if (state.selectedCredential) {
      if (state.selectedCredential._id === payload._id) {
        state.selectedCredential.unread_count = payload.unread_count;
      }
      if (credentials) {
        credentials.unread_count = payload.unread_count;
      }
    }
  },
  SET_SELECTED_CREDENTIAL (state, payload) {
    state.selectedCredential = payload;
    const params = new URLSearchParams(window.location.search);
    if (payload && payload._id) {
      params.set('selectedCred', payload._id);
      window.history.replaceState(null, null, `${window.location.pathname}?${params.toString()}`);
    }
  },
};

const actions = {
  async SET_CREDENTIALS_LIST ({ commit }, payload) {
    const credentials = payload || await getAllCredentials();
    commit('SET_CREDENTIALS_LIST', credentials);
    return credentials;
  },
  async SET_CREDENTIALS ({ commit }, payload) {
    const credentials = payload || await getAllCredentials();
    commit('SET_CREDENTIALS_LIST', credentials);
    return credentials;
  },
  async SET_SELECTED_CREDENTIAL ({ commit }, payload) {
    commit('SET_SELECTED_CREDENTIAL', payload);
  },
  async SET_SELECTED_CREDENTIAL_FROM_BACK ({ commit }, payload) {
    const credential = await getCredential(payload);
    commit('SET_SELECTED_CREDENTIAL', credential);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
