import { defaultFilters } from '@/data/defaulFilters.js';
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import { setWorkflowFilters } from '@/use/setWorkflowFilters';

export default {
  computed: {
    ...mapGetters({
      workflowsList: 'workflows/workflowsList',
      labelsList: 'labels/labelsList',
    }),
  },
  methods: {
    getFiltersData () {
      const result = [];
      const labelsCategory = {
        category: {
          name: this.$tc('common.label', 2),
          _id: 'labels',
          icon: 'bx-label',
        },
        items: this.labelsList,
      };
      const statisticsCategory = {
        category: {
          name: this.$t('common.statistics'),
          _id: 'statistics',
          icon: 'bx-bar-chart',
        },
        items: [
          {
            _id: 'with_opened_email',
            name: this.$t('statistics.emailOpenRate'),
          },
          {
            _id: 'with_bounced_email',
            name: this.$t('statistics.bounceRate'),
          },
        ],
      };
      const campaignsCategory = {
        category: {
          name: this.$tc('common.campaign', 2),
          _id: 'workflows',
          icon: 'bx-briefcase',
        },
        items: setWorkflowFilters(this.$isDevSpace),
      };
      const defaultCategories = cloneDeep(defaultFilters.value);
      return result.concat(
        campaignsCategory,
        statisticsCategory,
        defaultCategories,
        labelsCategory
      );
    },
  },
};
