<template>
  <div class="flex-column body-basic">
    <div class="flex-column flex-gap-4">
      <div class="flex-row flex-gap-4">
        <a
          :href="profile.link"
          class="text_m text_weight_accent hovered hovered_primary"
          target="_blank"
        >
          {{ profile.full_name }}
        </a>
        <default-label
          v-if="profile.wait_reasons.is_stuck"
          size="xs"
          label-name="Pending"
          custom-type="warning"
        />

        <p
          class="text_xs"
          :style="{ color: profileStatusColor }"
        >
          &bull; {{ profile.status | normalizeNameByStatus }}
        </p>
        <p
          v-if="profile.tag"
          class="text_xs"
          :style="{ color: profileStatusColor }"
        >
          &bull; {{ profile.tag | normalizeNameByStatus }}
        </p>
      </div>
      <div class="flex flex-align-items-center flex-justify-content-between">
        <p class="text_xs color_tertiary">
          {{ profile.occupation }}
        </p>
      </div>
    </div>
    <div class="flex flex-align-items-center flex-justify-content-between mt-1">
      <div>
        <button
          ref="labelsTooltip"
          class="default-button color_primary flex flex-gap-4 flex-align-items-center"
          @click.stop="showLablesTooltip = !showLablesTooltip"
        >
          <i class="bx bx-purchase-tag icon_size_16" />
          <span class="text_xs text_nowrap">
            {{ $tc('common.label', 2) }}
          </span>
        </button>
        <labels-tooltip
          v-closable="{
            exclude: ['labelsTooltip'],
            handler: () => showLablesTooltip = false,
          }"
          class="body-basic__labels-tooltip"
          :custom-style="{ maxWidth: '250px', transform: 'inherit', top: '4px' }"
          :show-lables-tooltip.sync="showLablesTooltip"
          :show-button="false"
          :chosen-labels="profile.labels"
          :all-labels="labelsList"
          @add-label="addLabel"
          @delete-label="deleteLabel"
          @delete-profile-label="deleteProfileLabel"
          @add-profile-label="addProfileLabel"
        />
      </div>
      <default-button
        v-if="profile.tag !== 'interested'"
        :title="$t('actions.markAsLead')"
        icon-name="bx-check"
        title-color="color_primary"
        size="s"
        color="none"
        @action="$emit('update-profile-prop', { tag: 'interested' });"
      />
      <default-button
        v-else
        :title="$t('actions.markAsCustomer')"
        icon-name="bx-check"
        title-color="color_primary"
        size="s"
        color="none"
        @action="$emit('update-profile-prop', { tag: 'lead' });"
      />
    </div>
    <div
      class="flex-row flex-gap-4 flex-justify-content-end"
    >
      <labels-list
        v-if="profile.labels.length"
        :is-need-max-labels="false"
        class="flex-grow-1 flex-wrap"
        :labels="profile.labels"
      />
    </div>
    <item-experience
      class="mt-2"
      :experience="profileCompany"
      :is-experience="false"
    />
  </div>
</template>
<script>
  import DefaultLabel from '@/components/base/uiKit/DefaultLabel';
  import LabelsList from '@/components/base/LabelsList';
  import LabelsTooltip from '@/components/peoplePage/LabelsTooltip';
  import ItemExperience from '@/components/inboxPage/info/experience/ItemExperience';

  import { normalizeNameByStatusFilterMixin } from '@/mixins/normalizeNameByStatusFilterMixin';
  import { differentFunctionsMixin } from '@/mixins/differentFunctionsMixin';
  import variables from '@/styles/_variables.module.scss';
  import { mapGetters } from 'vuex';
  import { cloneDeep } from 'lodash';

  import {
    addProfileLabel,
    deleteProfileLabel
  } from '@/api/profilesMethods';
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';

  export default {
    name: 'BodyBasic',
    components: {
      DefaultButton,
      DefaultLabel,
      LabelsList,
      LabelsTooltip,
      ItemExperience,
    },
    mixins: [normalizeNameByStatusFilterMixin, differentFunctionsMixin],
    props: {
      profile: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        showLablesTooltip: false,
      };
    },
    computed: {
      ...mapGetters({
        labelsList: 'labels/labelsList',
        selectedCredential: 'credentials/selectedCredential',
      }),
      profileStatusColor () {
        return variables[`status-${this.profile.tag}`] || variables[`status-${this.profile.status}`];
      },
      profileCompany () {
        return {
          link: this.profile?.company_domain || '#',
          name: this.profile?.company_name,
          positions: [{ name: this.profile.position }],
        };
      },
    },
    methods: {
      addLabel (label) {
        this.addProfileLabel(label._id);
      },
      deleteLabel (labelId) {
        const updatedProfile = cloneDeep(this.profile);
        updatedProfile.labels = updatedProfile.labels.filter(l => l._id !== labelId);
        this.$emit('update-profile', updatedProfile);
      },
      async deleteProfileLabel (labelId) {
        try {
          const updatedProfile = cloneDeep(this.profile);
          updatedProfile.labels = updatedProfile.labels.filter(l => l._id !== labelId);
          this.$emit('update-profile', updatedProfile);
          await deleteProfileLabel(updatedProfile._id, labelId, this.selectedCredential._id);
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
      async addProfileLabel (labelId) {
        try {
          const newLabel = this.labelsList.find(l => l._id === labelId);
          const updatedProfile = cloneDeep(this.profile);
          updatedProfile.labels = updatedProfile.labels.concat(newLabel);
          this.$emit('update-profile', updatedProfile);
          await addProfileLabel(updatedProfile._id, labelId, this.selectedCredential._id);
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
    },
  };
</script>
