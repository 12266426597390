import { computed } from 'vue';

const { useStore } = require('@/store');
const store = useStore();

const useConfig = () => {
  const $config = computed(() => store.getters['user/user']?.config);
  return { $config };
};

const isDevSpace = () => {
  const $isDevSpace = computed(() => store.getters['account/account']?._id === '601adbd29a8e0086fd0ec2fc');
  return { $isDevSpace };
};
export { useConfig, isDevSpace };
